console.log(sessionStorage.getItem('access_token_web'),'window.sessionStorage.getItem')
const POST_HEADER = {

    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${sessionStorage.getItem('access_token_web')}`
}

const GET_HEADER = {
        //  
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('access_token_web')}`
        }
   
}



export {
    POST_HEADER,
    GET_HEADER
}