// export const BASE_URL = 'http://192.168.1.11:8050'
// export const BASE_URL = 'https://backend.credhub.in'
// export const BASE_URL = 'http://35.245.215.29:8050'
// export const BASE_URL = 'http://34.139.202.55:8050'
// export const BASE_URL = 'http://34.148.168.156:8080'
// export const BASE_URL = 'http://localhost:8050'
// export const CAREPAY_BASE_URL = 'http://192.168.1.7:8075/'
export const BASE_URL = process.env.REACT_APP_URL
export const CAREPAY_BASE_URL = process.env.REACT_APP_URL
//export const CAREPAY_BASE_URL = 'https://backend.carepay.money';
export const CARE_AI_URL = 'http://54.226.42.55:8002/'

console.log(process.env, 'util')
// export const CAREPAY_BASE_URL = 'http://192.168.1.6:8075'

export const LICENCE_ID = 'ijGiDcwZcuOzjZlHgi06OaDfQlsDO6Sy'

export const APIS = {
    // super admin 
    LOGIN: BASE_URL + '/auth/admin?emailId=',
    // LOGIN: CAREPAY_BASE_URL + '/getEmailPasswordValidationStatus?emailId=',
    SAVE_UPDATE_PRODUCT: BASE_URL + '/saveUpdateProductDetail',
    GET_PRODUCT: BASE_URL + '/getProductDetailByPageNo?pageNumber=',
    DELETE_PRODUCT: BASE_URL + '/deleteProductById?id=',
    DELETE_BY_PRODUCT_CODE: BASE_URL + '/deleteProductDetailByProductCode?productCode=',
    GET_PRDUCT_TABLE_COUNT: BASE_URL + '/getProductDetailCountByProductCode?productCode=',
    SAVE_UPDATE_MODULE: BASE_URL + '/saveUpdateModuleDetail',
    GET_MODULE: BASE_URL + '/getModuleByPageNo?pageNumber=',
    DELETE_MODULE: BASE_URL + '/deleteModuleById?id=',
    DELETE_MODULE_BY_PRODUCT_CODE: BASE_URL + '/deleteModuleByProductCodeModuleCode?productCode=&moduleCode=',
    GET_MODULE_TABLE_COUNT: BASE_URL + '/getModuleCountByProductAndModuleCode?productCode=',

    SAVE_UPDATE_ROLE: CAREPAY_BASE_URL + '/saveOrUpdateRole',
    GET_ROLE: CAREPAY_BASE_URL + '/getRolesByPageNo?pageNumber=',
    DELETE_ROLE: CAREPAY_BASE_URL + '/deleteRoleById?id=',
    GET_ROLE_COUNT: CAREPAY_BASE_URL + '/getRolesPageCount',


    SAVE_UPDATE_LICENCE: BASE_URL + '/saveUpdateLicenseDetail',
    GET_LICENCE: BASE_URL + '/getLicenseByPageNo?pageNumber=',
    DELETE_LICENCE: BASE_URL + '/deleteLicense?id=',
    GET_LICENCE_TABLE_COUNT: BASE_URL + '/getLicenseCountByEmailAndMobileNo?emailId=',

    SAVE_UPDATE_USERS: CAREPAY_BASE_URL + '/saveOrUpdateAdminUsers',
    GET_USERS: CAREPAY_BASE_URL + '/getAdminUsersByCountEmailAndMobile?pageNumber=',
    DELETE_USER: CAREPAY_BASE_URL + '/deleteAdminUsersByAdminId?id=',
    GET_USER_TABLE_COUNT: CAREPAY_BASE_URL + '/getAdminUsersCountByCountEmailAndMobile?emailId=',

    SAVE_UPDATE_WORKFLOW: BASE_URL + '/saveOrUpdateWorkFlow',
    GET_WORKFLOW: BASE_URL + '/getWorkFlowByPageEntryLicenseAndLenderId?pageNumber=',
    DELETE_WORKFLOW: BASE_URL + '/deleteWorkFlowById?id=',
    GET_WORKFLOW_TABLE_COUNT: BASE_URL + '/getWorkFlowCountByPageEntryLicenseAndLenderId?lenderId=',

    SAVE_UPDATE_MAIL_CONFIG: BASE_URL + '/saveOrUpdateMailConfig',
    GET_MAIL_CONFIG: BASE_URL + '/getEmailConfigByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_MAIL_CONFIG: BASE_URL + '/deleteEmailConfigById?id=',
    GET_MAIL_CONFIG_TABLE_COUNT: BASE_URL + '/getEmailConfigCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_WHATSAPP_CONFIG: BASE_URL + '/saveOrUpdateWhatsAppConfig',
    GET_WHATSAPP_CONFIG: BASE_URL + '/getWhatsAppConfigByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_WHATSAPP_CONFIG: BASE_URL + '/deleteWhatsAppConfigById?id=',
    GET_WHATSAPP_TABLE_COUNT: BASE_URL + '/getWhatsAppConfigCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_TEXT_MSG_CONFIG: BASE_URL + '/saveOrUpdateTextMsgConfig',
    GET_TEXT_MSG_CONFIG: BASE_URL + '/getTextMsgConfigByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_TEXT_MSG_CONFIG: BASE_URL + '/deleteTextMsgConfigById?id=',
    GET_TEXT_MSG_TABLE_COUNT: BASE_URL + '/getTextMsgConfigCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_DOCUMENT_CONFIG: BASE_URL + '/saveOrUpdateDocumentConfig',
    GET_DOCUMENT_CONFIG: BASE_URL + '/getDocConfigByPageEntryLicenseAndProduct?pageNumber=',
    DELETE_DOCUMENT_CONFIG: BASE_URL + '/deleteDocConfigById?id=',
    GET_DOCUMENT_TABLE_COUNT: BASE_URL + '/getDocConfigCountByLicenseAndProduct?licenseId=',

    SAVE_UPDATE_CREDIT_ASSESSMENT_CONFIG: BASE_URL + '/saveOrUpdateCreditConfig',
    GET_CREDIT_ASSESSMENT_CONFIG: BASE_URL + '/getCreditConfigByPageEntryLicenseAndLenderId?pageNumber=',
    DELETE_CREDIT_ASSESSMENT_CONFIG: BASE_URL + '/deleteCreditConfigById?id=',
    DELETE_CREDIT_ASSESSMENT_CONFIG_BY_NBFCID: BASE_URL + '/deleteCreditConfigByNbfcId?nbfcId=',

    DELETE_CREDIT_ASSESSMENT_CONFIG_BY_LENDER: BASE_URL + '/deleteCreditConfigByLenderId?lenderId=',
    GET_CREDIT_ASSESSMENT_TABLE_COUNT: BASE_URL + '/getCreditConfigCountByLicenseAndLenderId?lenderId=',

    GET_LENDER: BASE_URL + '/getListOfEntries?pageNo=',
    DELETE_LENDER: BASE_URL + '/deleteLenderByLenderId?lenderId=',
    GET_LENDER_COUNT: BASE_URL + '/getListOfEntriesCount?emailId=',
    SAVE_UPDATE_LENDER_PERSONAL: BASE_URL + '/saveOrUpdateLenderDetail',
    GET_LENDER_PERSONAL: BASE_URL + '/getLendersDetailByLenderId?lenderId=',
    // GET_ADDRESS_DETAIL_LENDER: BASE_URL + '/getAddressDetailByLenderIdAndType?lenderId=',
    GET_ADDRESS_DETAIL_LENDER: BASE_URL + '/getAddressDetails?lenderId=',

    GET_BANK_DETAIL_LENDER: BASE_URL + '/getAccountDetailsByLenderId?lenderId=',

    // GET_COMMUNITY: BASE_URL + '/getDoctorsList?pageNumber=',
    DELETE_COMMUNITY: BASE_URL + '/deleteCommunityInfoByCommunityId?communityId=',
    // GET_COMMUNITY_COUNT: BASE_URL + '/getDoctorsListCount?emailId=',
    SAVE_UPDATE_COMMUNITY_PERSONAL: BASE_URL + '/saveOrUpdateCommunityDetails',
    GET_COMMUNITY_PERSONAL: BASE_URL + '/getCommunityDetailsByCommunityId?communityId=',
    SAVE_UPDATE_COMMUNITY_PROFESSIONAL: BASE_URL + '/saveOrUpdateCommunityProfessionalDetails',
    GET_COMMUNITY_PROFESSIONAL: BASE_URL + '/getCommunityProfDetailsByCommunityId?communityId=',
    GET_ADDRESS_DETAIL_COMMUNITY: BASE_URL + '/getAddressDetailByCommunityIdAndType?communityId=',
    GET_BANK_DETAIL_COMMUNITY: BASE_URL + '/getAccountDetailsByCommunityId?communityId=',

    SAVE_UPDATE_ADDRESS: BASE_URL + '/saveOrUpdateAddressDetails',
    SAVE_UPDATE_BANK: BASE_URL + '/saveOrUpdateAccountDetails',
    SAVE_UPDATE_DOCUMENT: BASE_URL + '/saveAndUploadFileAndDocDetail',
    SAVE_UPDATE_DOCUMENT_WITH_GET_URL: BASE_URL + '/saveAndUploadFileAndDocDetailAndGetUrl',

    GET_DOCUMENT_DETAIL: BASE_URL + '/getDocumentsList?pageNumber=',

    SAVE_UPDATE_MAIL_TEMPLATE_CONFIG: BASE_URL + '/saveOrUpdateMailTemplates',
    GET_MAIL_TEMPLATE_CONFIG: BASE_URL + '/getEmailTemplatesByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_MAIL_TEMPLATE_CONFIG: BASE_URL + '/deleteEmailTemplateById?id=',
    GET_MAIL_TEMPLATE_TABLE_COUNT: BASE_URL + '/getEmailTemplatesCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_WHATSAPP_TEMPLATE_CONFIG: BASE_URL + '/saveOrUpdateWhatsAppTemplateConfig',
    GET_WHATSAPP_TEMPLATE_CONFIG: BASE_URL + '/getWhatsAppTemplatesByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_WHATSAPP_TEMPLATE_CONFIG: BASE_URL + '/deleteWhatsAppTemplatesById?id=',
    GET_WHATSAPP_TEMPLATE_TABLE_COUNT: BASE_URL + '/getWhatsAppTemplatesCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_TEXT_MSG_TEMPLATE_CONFIG: BASE_URL + '/saveOrUpdateTextMsgTemplateConfig',
    GET_TEXT_MSG_TEMPLATE_CONFIG: BASE_URL + '/getTextMsgTemplatesByLenderLicenseAndCommunityId?pageNumber=',
    DELETE_TEXT_MSG_TEMPLATE_CONFIG: BASE_URL + '/deleteTextMsgTemplatesById?id=',
    GET_TEXT_MSG_TEMPLATE_TABLE_COUNT: BASE_URL + '/getTextMsgTemplatesCountByLenderLicenseAndCommunityId?lenderId=',

    SAVE_UPDATE_ESIGN_CONFIG: BASE_URL + '/saveOrUpdateEsignConfig',
    GET_ESIGN_CONFIG: BASE_URL + '/getEsignConfigByCommunityAndLenderId?pageNumber=',
    DELETE_ESIGN_CONFIG: BASE_URL + '/deleteEsignConfigById?id=',
    GET_ESIGN_TABLE_COUNT: BASE_URL + '/getEsignConfigCountByCommunityAndLenderId?lenderId=',

    SAVE_UPDATE_ENASH_CONFIG: BASE_URL + '/saveEnachConfig',
    GET_ENASH_CONFIG: BASE_URL + '/getEnachConfig?pageNumber=',
    DELETE_ENASH_CONFIG: BASE_URL + '/deleteEnachConfig?id=',
    GET_ENASH_TABLE_COUNT: BASE_URL + '/getEnachConfigCount?lenderId=',

    SAVE_UPDATE_STAGE: BASE_URL + '/saveOrUpdateStageDetails',
    GET_STAGE: BASE_URL + '/getStageDetails?pageNumber=',
    DELETE_STAGE: BASE_URL + '/deleteStageDetailsById?id=',
    GET_STAGE_TABLE_COUNT: BASE_URL + '/getStageCountDetails?id=',

    SAVE_UPDATE_DOC_TEMPLATE: BASE_URL + '/saveOrUpdateDocumentTempConfig',
    GET_DOC_TEMPLATE: BASE_URL + '/getDocumentTempListByPageEntry?pageNumber=',
    DELETE_DOC_TEMPLATE: BASE_URL + '/deleteDocumentTempById?id=',
    GET_DOC_TEMPLATE_TABLE_COUNT: BASE_URL + '/getDocumentTempCountByPageEntry',
    GET_DOC_TEMPLATE_BY_NAME: BASE_URL + '/getDocumentTempListByDocName?name=',

    GET_ESIGN_VARIABLE_COLUMN_CONFIG: BASE_URL + '/getEsignVariableColumnConfiguration',
    SAVE_ESIGN_VARIABLE_CONFIG_By_LENDER: BASE_URL + '/saveEsignVariableConfigurationByLenderId',
    GET_ESIGN_VARIABLE_CONFIG_BY_LENDER: BASE_URL + '/getEsignVariableConfigurationByLenderId?lenderId=',

    GET_ALL_COLUMN_LIST: BASE_URL + '/sheets/getAllColumnList',
    DOWNLOAD_SHEET: BASE_URL + '/sheets/downloadSheet',


    // community
    COMMUNITY_LOGIN: BASE_URL + '/communityLogin?emailId=',
    COM_GET_LOAN_LEADS: BASE_URL + '/getLoanLeads?pageNumber=',
    COM_GET_LOAN_LEAD_COUNT: BASE_URL + '/getLoanLeadsCount?communityId=',
    COM_LEAD_SAVE_USER: BASE_URL + '/saveOrUpdateUserDetails',
    COM_LEAD_GET_USER: BASE_URL + '/getUserDetailByUserId?userId=',
    COM_LEAD_SAVE_USER_BUSINESS: BASE_URL + '/saveOrUpdateUserBusinessDetail',
    COM_LEAD_GET_USER_BUSINESS: BASE_URL + '/getUserBusinessDetailByUserId?userId=',
    COM_LEAD_SAVE_USER_SALARIED: BASE_URL + '/saveSalariedDetails',
    COM_LEAD_GET_USER_SALARIED: BASE_URL + '/getSalariedDetails?userId=',
    COM_LEAD_SAVE_USER_PROPERTY: BASE_URL + '/saveOrUpdateHousePropertyDetail',
    COM_LEAD_GET_USER_PROPERTY_BY_USERID: BASE_URL + '/getHousePropertyDetailByUserId?userId=',
    COM_LEAD_GET_USER_PROPERTY_BY_LOANID: BASE_URL + '/getHousePropertyDetailByLoanId?loanId=',
    COM_LEAD_DELETE_USER_PROPERTY: BASE_URL + '/deleteHousePropertyDetailById?id=',
    COM_LEAD_SAVE_USER_DIRECTOR: BASE_URL + '/saveOrUpdateDirectorDetail',
    COM_LEAD_GET_USER_DIRECTOR_BY_USERID: BASE_URL + '/getDirectorDetailByUserId?userId=',
    COM_LEAD_GET_USER_DIRECTOR_BY_LOANID: BASE_URL + '/getDirectorDetailByLoanId?loanId=',
    COM_LEAD_DELETE_USER_DIRECTOR: BASE_URL + '/deleteDirectorDetailById?id=',
    COM_LEAD_SAVE_USER_ADDRESS: BASE_URL + '/saveOrUpdateAddressDetailsBusiness',
    COM_LEAD_GET_USER_ADDRESS: BASE_URL + '/getAddressDetailByUserIdAndType?userId=',
    COM_LEAD_SAVE_USER_LOAN: BASE_URL + '/saveLoanDetails',
    COM_LEAD_GET_USER_LOAN: BASE_URL + '/getLoanDetailsByLoanId?loanId=',
    COM_LEAD_SAVE_USER_BANK: BASE_URL + '/saveOrUpdateAccountDetails',
    COM_LEAD_GET_USER_BANK: BASE_URL + '/getAccountDetailsByUserId?userId=',
    COM_LEAD_COMMUNICATION: BASE_URL + '/sendSingleTextToUser',
    COM_GET_COMMUNICATION: BASE_URL + '/getCommunicationDetailByTypeAndId?pageNumber=',
    COM_GET_BULK_COMMUNICATION: BASE_URL + '/getBulkCommunicationData?pageNumber=',
    COM_GET_BULK_COMMUNICATION_COUNT: BASE_URL + '/getBulkCommunicationDataCount?date=',
    COM_GET_NUMBER_OF_MEMBERS: BASE_URL + '/getNumberOfMembersAddedByUser?communityId=',
    COM_GET_NUMBER_OF_LEADS_GENERATED: BASE_URL + '/getNumberOfLeadsGeneratedByUser?communityId=',
    COM_GET_LEADS_STATUS: BASE_URL + '/getCommunityLeadsByStatus?communityId=',
    COM_GET_DASHBOARD_TREE: BASE_URL + '/getCommunityTreeByCommunityId?level=3&communityId=',
    COM_GET_LEAD_LEDGER: BASE_URL + '/getLeadsWithLedgerCalculation?communityId=',
    COMMUNITY_POST_QUERIES: BASE_URL + '/saveOrUpdateCommunityMemberQueryDetails',
    COMMUNITY_GET_QUERIES_FOR_MEMBER: BASE_URL + '/getCommunityMemberQueryDetailByCommunityId?communityId=',
    COMMUNITY_GET_QUERIES_FOR_ADMIN: BASE_URL + '/getCommunityMemberQueryDetailByParentCommunityId?parentCommunityId=',
    COMMUNITY_UPDATE_QUERY_STATUS: BASE_URL + '/updateQueryStatus?id=',
    COM_BULK_COMMUNICATION: BASE_URL + '/sendBulkTextToUserByFile',
    LOAN_AMOUNT_CONFIG_API: BASE_URL + '/getCreditConfigForLoan?lenderId=',


    //Lead By loanId
    GET_LOAN_LEAD_BY_LOANID: BASE_URL + "/getLoanLeadByLoanId?loanId=",


    // lender
    LENDER_LOGIN: BASE_URL + '/lenderLogin?emailId=',
    LENDER_GET_LOAN_LEADS: BASE_URL + '/getLoanLeads?pageNumber=',
    LENDER_GET_LOAN_LEAD_COUNT: BASE_URL + '/getLoanLeadsCount?lenderId=',
    SAVE_APPLICATION_STATUS: BASE_URL + '/saveApplicationStatus?loanId=',
    DISBURSE_APPLICATION: BASE_URL + '/disburseStatus?loanId=',
    APPROVE_APPLICATION: BASE_URL + '/approveApplication',
    GET_LOAN_EMI: BASE_URL + '/getLoanEmiByLoanId?loanId=',
    GET_LOAN_ACTIVITY: BASE_URL + '/getStatusActivityList?loanId=',
    GET_LENDERS_LEADS_STATUS: BASE_URL + '/getLenderLeadsByStatus?lenderId=',
    GET_AMOUNT_DISBURSE_BY_LENDER: BASE_URL + '/getAmountDisbursedByLender?lenderId=',

    SAVE_LENDER_COMMENTS: BASE_URL + '/saveComments',
    SAVE_LENDER_EMPLOYEES: BASE_URL + '/saveOrUpdateEmployeeDetail',
    GET_LENDER_EMPLOYEES_BY_LENDERID: BASE_URL + '/getEmployeeDetailsByLenderId?pageNumber=',
    GET_LENDER_EMPLOYEES_BY_EMPLOYEEID: BASE_URL + '/getEmployeeDetailsByEmployeeId?employeeId=',
    GET_LENDER_EMPLOYEES_COUNT_BY_LENDERID: BASE_URL + '/getEmployeeCountByLenderId?lenderId=',
    LENDER_GET_INCOMING_LEADS: BASE_URL + '/getIncomingLeads?pageNumber=',
    GET_LENDER_DEPARTMENT_BY_LENDERID: BASE_URL + '/getLenderDepartment?lenderId=',
    DELETE_COMMENT: BASE_URL + "/deleteComment?id=",
    POKE: BASE_URL + "/increaseReminderCount?id=",


    //CONTACT US API//
    SAVE_CONTACT_US_DETAILS: BASE_URL + '/saveContactUsDetails',
    // GET_CONTACT: BASE_URL + '/getContactUsDetail?pageNumber=',
    // DELETE_CONTACT: BASE_URL + '/deleteContactUsDetail?id=',
    // GET_CONTACTS_COUNT: BASE_URL + '/getContactUsDetailCount?date=',

    //ESIGN API//
    GET_ESIGN_REQUEST_BY_LOAN_ID: BASE_URL + '/eSignRequest?loanId=',
    GET_ESIGN_WIDGET_TOKEN_BY_LOAN_ID: BASE_URL + '/widgetToken?loanId=',
    GET_ESIGN_DOCKET_INFO_BY_LOAN_ID: BASE_URL + '/getDocketInfo?loanId=',
    GET_ESIGN_SIGNATURE_STATUS_BY_DOCUMENT_ID: BASE_URL + '/getSignatureStatus?documentId=',
    GET_ENACH_ESIGN_STATUS_BY_LOAN_ID: BASE_URL + '/getEsignAndEnachStatusByLoanId?loanId=',
    GET_LOAN_AGGREMENT_URL: BASE_URL + '/getLoanAggrementById?loanId=',

    //ENACH//
    GET_RAZORPAY_ORDERID_AND_CUSTOMER_ID: BASE_URL + '/getRazorPayOrderIdAndCustomerId?loanId=',
    GET_VERIFY_MANDATE_AND_SAVE_TOKEN: BASE_URL + 'verifyMandateAndSaveToken?userId=',
    GET_DEDUCT_USER_PAYMENT: BASE_URL + '/deductUserPayment?loanId=',
    GET_DECATIVATE_NACH: BASE_URL + '/deactivateEnach?userId=',
    GET_LOAN_DETAILS_BY_LOAN_ID: BASE_URL + '/getLoanDetailByLoanId?loanId=',
    GET_ACTIVITY_API: BASE_URL + '/getStatusActivityList?loanId=',
    GET_EMI_DETAILS_API: BASE_URL + '/getLoanEmiByLoanId?loanId=',

    //CRIF

    // TEST_CRIF: 'https://admin.phocket.in/getCrifData?userId=JWrs8yt1qBXmuKhVjCKEIuJg0CqA6yh9&regenerateParam=0',

    GET_CRIF_DATA_BY_USER_ID: BASE_URL + '/getCrifData?userId=',
    GET_COMMENTS: BASE_URL + '/getComments?loanId=',



    //KARZA API//

    INITIATE_AADHAR_CONSENT_API: BASE_URL + '/karza/api/initiateAadhaarConsent?userId=',//&type=&aadhaarNo=
    AADHAR_OTP_API: BASE_URL + '/karza/api/getAadhaarOtp?userId=',//&aadhaarNo=
    DOWNLOAD_AADHAR_XML: BASE_URL + '/karza/api/downloadAadhaarXml?userId=',//&otp=&shareCode=
    DIGILOCKER_OTP_API: BASE_URL + '/karza/api/getOtpDigiLocker?userId=',//&otp=&aadhaarNo=
    DOWNLOAD_AADHAR_XML_DIGILOCKER: BASE_URL + '/karza/api/downloadXmlAadhaarDigiLocker?userId=',
    PAN_PROFILE_DETAILS: BASE_URL + '/karza/api/getPanProfileDetail?userId=',
    CONSENT_AADHAR_NUMBER: BASE_URL + '/karza/api/consentAadhaarNumberVerification?userId=',//&name=
    AADHAR_NUMBER_VERIFICATION: BASE_URL + '/karza/api/aadhaarNumberVerification?userId=',//&aadhaarNo=
    PAN_AUTHENTICATION_API: BASE_URL + '/karza/api/panAuthenticationCheck?userId=',//&panNumber=
    PAN_STATUS_CHECK_API: BASE_URL + '/karza/api/panStatusCheck?userId=',
    BANK_ACCOUNT_CHECK_API: BASE_URL + '/karza/api/bankAccountCheck?ifsc=',//&accountNumber=&accountHolderName=&nameMatchType=
    GET_AADHAR_XML_DATA_BY_USER_ID: BASE_URL + '/karza/api/getAadhaarXmlDataByUserId?userId=',
    GET_AADHAR_VERIFICATION_DETAILS_BY_USER_ID: BASE_URL + '/karza/api/getAadhaarVerificationDetails?userId=',
    GET_PAN_PROFILE_DETAILS_BY_USER_ID: BASE_URL + '/karza/api/getPanProfileDetails?userId=',
    GET_DIGILCKER_DATA: BASE_URL + '/karza/api/getDigilockerDataWithPagination?communityId=',
    GET_AADHAAR_DATA: BASE_URL + '/karza/api/getAadhaarDataByCommunityId?communityId=',
    GET_AADHAAR_VERIFICATION_DATA: BASE_URL + '/karza/api/getAadhaarVerificationDataByCommunityId?communityId=',
    GET_PAN_PROFILE_DATA: BASE_URL + '/karza/api/getPanProfileDataByCommunityId?communityId=',
    GET_AADHAAR_XML_DATA: BASE_URL + '/karza/api/getAadhaarXmlDetailByUserId?user_id=',
    GET_PAN_PROFILE_DETAIL: BASE_URL + '/karza/api/getPanProfileDetail?userId=',
    GET_PAN_PROFILE_DETAILS: BASE_URL + '/karza/api/getPanProfileDetails?userId=',



    GET_DIGILOCKER_DATA_COUNT: BASE_URL + '/karza/api/digiLockerDataCountByCommunityId?communityId=',
    GET_AADHAAR_DATA_COUNT: BASE_URL + '/karza/api/aadhaarDataCountByCommunityId?communityId=',
    GET_AADHAAR_VER_DATA_COUNT: BASE_URL + '/karza/api/aadhaarVerificationDataCountByCommunityId?communityId=',
    GET_PAN_PROFILE_DATA_COUNT: BASE_URL + '/karza/api/panProfileDataCountByCommunityId?communityId=',


    GET_SEARCH_BASIS_PAN: BASE_URL + '/karza/api/gstSearchBasisPan?userId=',
    GSP_GST_AUTHENTICATION: BASE_URL + '/karza/api/gspGstAuthentication?userId=',
    GSP_GST_RETURN_FILLING: BASE_URL + '/karza/api/gspGstReturnFiling?userId=',
    COMPANY_SEARCH_BY_NAME: BASE_URL + '/karza/api/companySearchByName?userId=',
    GST_AUTHENTICATION: BASE_URL + '/karza/api/GSTAuthentication?userId=',
    MCA_SIGNATORIES: BASE_URL + '/karza/api/mcaSignatories?userId=',
    GET_BANK_VERIFICATION_DETAIL: BASE_URL + '/karza/api/getBankVerificationDetails?accountNumber=',
    GET_GST_BASIS_SEARCH: BASE_URL + '/karza/api/getGSTSearchBasis?userId=',
    GET_MCA_SIGNATORIES: BASE_URL + '/karza/api/getMCASignatories?userId=',





    GET_BANK_BRANCH_DETAIL: BASE_URL + '/codeDetail?code=',  //&type=(zip/branch)
    GET_FINBIT_TOKEN: BASE_URL + '/fetchFinBitToken',
    SEND_COMPLETE_PROFILE_LINK: BASE_URL + '/sendProfileCompletitionToMember?communityId=',
    ASSIGN_LENDER_API: BASE_URL + '/changeLeadLenderAssignment?lenderId=',
    ASSIGN_LENDER_LIST_API: BASE_URL + '/assginLenderList',
    GET_LENDER_LIST: BASE_URL + '/getLenderForLead?loanId=',

    SEND_WELCOME_MAIL: BASE_URL + '/sendWelcomeMail?communityId=',
    SEND_DOCUMENT_LINK: BASE_URL + '/sendDocsUploadLinkToMember?communityId=',
    FORGOT_PASSWORD_MAIL_COMMUNITY: BASE_URL + '/sendForgetPassMail?emailId=',
    RESET_COMMUNITY_PASSWORD: BASE_URL + '/resetCommunityPasswordByCommunityId?communityId=',
    LOAN_CALCULATOR_API: BASE_URL + '/emiCalculator?disberseDate=',


    GET_REWARDS_CONFIG: BASE_URL + '/getListOfRewardDetails?noOfEntry=',
    SAVE_REWARDS_CONFIG: BASE_URL + '/addRewardDetails',
    GET_REWARDS_CONFIG_COUNT: BASE_URL + '/getRewardsCount',
    DELETE_REWARDS_CONFIG: BASE_URL + '/deleteByRewardId?rewardId=',

    GET_NBFC_DATA: BASE_URL + '/nbfc/api/getNbfcDetails?noOfEntry=',
    SAVE_NBFC_BY_FILE: BASE_URL + '/nbfc/api/saveNbfcDetailsByFile?file=',
    SAVE_NBFC_DATA: BASE_URL + '/nbfc/api/saveOrUpdateSingleNbfc',
    SAVE_NBFC_ADDRESS: BASE_URL + '/saveNbfcAddress',
    DELETE_NBFC_DATA: BASE_URL + '/nbfc/api/deleteNbfcDetails?nbfcId=',
    DELETE_NBFC_ADDRESS_DATA: BASE_URL + '/deleteNbfcAddress?nbfcId=',
    DELETE_NBFC_LENDING_DATA: BASE_URL + '/nbfc/api//deleteNbfcLendingDetails?nbfcId=',
    DELETE_NBFC_INTERACTION_DATA: BASE_URL + '/nbfcInteraction/api/deleteNbfcInteractionDetails?nbfcInteractionId=',
    SAVE_NBFC_CONFIG_DATA: BASE_URL + '/nbfc/product/saveOrUpdate',
    GET_NBFC_CONFIG_DATA: BASE_URL + '/nbfc/product/getByNbfcId?nbfcId=',




    GET_NBFC_COUNT: BASE_URL + '/nbfc/api/getNbfcDataCount?status=',
    GET_NBFC_ADDRESS_DATA: BASE_URL + "/getNbfcAddress?nbfcId=",

    GET_NOTIFICATIONS: BASE_URL + '/getNotifications',
    UPDATE_NOTIFICATIONS: BASE_URL + '/updateNotification?id=',
    UPDATE_ALL_NOTIFICATIONS: BASE_URL + '/updateNotificationByIds',


    //GST
    GST_TRRN_LOGIN: BASE_URL + '/gst/api/gstTrrnLogin?userId=',
    GST_TRRN_OTP_REQUEST: BASE_URL + '/gst/api/gstTrrnOtpRequest?userId=',
    GST_TRRN_OTP_SUBMIT: BASE_URL + '/gst/api/gstTrrnOtpSubmit?userId=',
    TRRN_WEBHOOK: BASE_URL + "/trrnWebhook",
    GET_LOGS_TOTAL_COUNT: BASE_URL + '/getCommunicationDetailByTypeAndIdCount?id=',
    GET_TRRN_AUTH_DATA_BY_COMMUNITY_ID: BASE_URL + '/gst/api/getGstTrrnAuthDataByCommunityId?communityId=',
    GET_TRRN_AUTH_DATA_COUNT_BY_COMMUNITY_ID: BASE_URL + '/gst/api/getGstTrrnAuthDataCountByCommunityId?communityId=',

    //ITR
    ITR_BUSINESS_INFO: BASE_URL + '/itr/api/itrBusinessInfo?userId=',
    ITR_SALARIED_INFO: BASE_URL + '/itr/api/itrSalariedInfo?userId=',
    ITR_26_AS_FORM_DETAILS: BASE_URL + '/itr/api/26ASFormDetails?userId=',
    ITR_ADVANCE_DETAILS: BASE_URL + '/itr/api/itrAdvancedDetails?userId=',


    GET_ITR_ADVANCE_DETAILS_BY_USERID: BASE_URL + '/itr/api/getItrAdvanceDetailsByUserId?userId=',
    GET_ITR_ADVANCE_DETAILS_COMMUNITY_ID: BASE_URL + '/itr/api/getItrAdvanceDetailsByCommunityId?communityId=',
    GET_ITR_ADVANCE_DETAILS_COUNT_COMMUNITY_ID: BASE_URL + '/itr/api/getItrAdvanceDetailsCountByCommunityId?communityId=',

    GET_ITR_DETAILS_BY_USERID: BASE_URL + '/itr/api/getItrDetailsByUserId?userId=',
    GET_ITR_DETAILS_COMMUNITY_ID: BASE_URL + '/itr/api/getItrDetailsByCommunityId?communityId=',
    GET_ITR_DETAILS_COUNT_COMMUNITY_ID: BASE_URL + '/itr/api/getItrDetailsCountByCommunityId?communityId=',

    GET_ITR_FORM26_BY_USERID: BASE_URL + '/itr/api/getForm26ASDataByUserId?userId=',
    GET_ITR_FORM26_DETAILS_COMMUNITY_ID: BASE_URL + '/itr/api/getForm26ASDataByCommunityId?communityId=',
    GET_ITR_FORM26_DETAILS_COUNT_COMMUNITY_ID: BASE_URL + '/itr/api/getForm26ASDataCountByCommunityId?communityId=',


    //FINBIT
    SAVE_ACCOUNT_UUID_FINBIT: BASE_URL + '/saveAccountUUIDFinBit',
    SAVE_ACCOUNT_UUID: BASE_URL + '/saveAccountUUID?userId=',
    GET_ACCOUNY_UUID: BASE_URL + '/getAccountUUID?userId=',
    GET_FINBIT_DATA: BASE_URL + '/getFinBitData?userId=',
    FETCH_FINBIT_TOKEN: BASE_URL + "/fetchFinBitToken",
    FINBIT_CALLBACK: BASE_URL + '/finbitCallBack?userId=',

    GET_ALL_FORUMS: BASE_URL + '/getForums?noOfEntry=',
    DELETE_FORUMS_COMMENT: BASE_URL + '/deleteForumCommentByCommentId?commentId=',
    GET_LIKE_COUNTS: BASE_URL + '/getLikesCount?forumId=',
    GET_LIKE_COMMENT_OR_FORUM: BASE_URL + '/likeForumOrComment?forumId=',
    GET_FORUM_DETAIL_BY_FORUMID: BASE_URL + '/getForumDetailByForumId?forumId=',
    GET_COMMENTS_BY_FORUM_FORUM_ID: BASE_URL + '/getAllForumCommentsByForumId?forumId=',
    SAVE_FORUM_COMMENT: BASE_URL + '/saveOrUpdateForumComment',
    CREATE_FORUMS: BASE_URL + '/saveOrUpdateForumDetail',
    DELETE_FORUM: BASE_URL + '/deleteForumDetailsByForumId?forumId=',
    SAVE_DOCUMENT_AND_GET_URL: BASE_URL + '/saveAndUploadFileAndDocDetailAndGetUrl',


    SAVE_NBFC_INTERACTION: BASE_URL + '/nbfcInteraction/api/saveOrUpdateNbfcInteractionDetails',
    GET_NBFC_INTERACTION: BASE_URL + '/nbfcInteraction/api/getNbfcInteractionDetails?noOfEntry=',
    SAVE_NBFC_LENDING_DATA: BASE_URL + '/nbfc/api/saveOrUpdateNbfcLendingDetails',
    UPDATE_NBFC_STATUS: BASE_URL + '/nbfc/api/changeStatus?status=',
    GET_NBFC_LENDING_DATA: BASE_URL + '/nbfc/api/getNbfcLendingDetails?nbfcId=',
    CHANGE_INTERACTION_STATUS_NBFC: BASE_URL + "/nbfc/api/changeInteractionStatus?nbfcId=",

    GET_BANK_CONFIG: BASE_URL + '/bank/getBankBranches?pageNumber=',
    GET_BANK_BRANCHES_BY_BRANCHID: BASE_URL + '/bank/getBankBranchesByBranchId?branchId=',
    ADD_SINGLE_BANK_CONFIG: BASE_URL + '/bank/addSingleBranch',
    ADD_BULK_BANK_CONFIG: BASE_URL + '/bank/addBranchDetailsByFile',
    GET_BANK_CONFIG_COUNT: BASE_URL + '/bank/getBranchDataCount?bankName=',
    DELETE_BANK_CONFIG: BASE_URL + '/bank/deleteBranch?branchId=',
    UPDATE_BANK_STATUS: BASE_URL + '/bank/changeStatus?status=',
    GET_BANK_SEARCH_DATA_MATCHING: BASE_URL + '/bank/getMatchingBankName?search=',
    GET_BANK_AND_NBFC_SEARCH_DATA_MATCHING: BASE_URL + '/getMatchingBankAndNbfcName?search=',
    GET_BANK_SEARCH_DATA_MATCHING_WITH_ID: BASE_URL + '/bank/getMatchingBankNameWithId?search=',
    GET_NBFC_SEARCH_DATA_MATCHING_WITH_ID: BASE_URL + '/nbfc/api/getMatchingNbfcs?search=',
    CHANGE_INTERACTION_STATUS_BANK: BASE_URL + "/bank/changeInteractionStatus?branchId=",
    ADD_NEWS: CAREPAY_BASE_URL + '/addNews',
    GET_NEWS_DATA: CAREPAY_BASE_URL + '/getNewsData?newsId=',
    // ADD_NEWS: CAREPAY_BASE_URL + '/news/addNews',
    GET_NEWS_DATA_COUNT: CAREPAY_BASE_URL + '/getNewsDataCount?newsId=',
    DELETE_NEWS: CAREPAY_BASE_URL + '/deleteNews?newsId=',
    ADD_NOTIFICATIONS: BASE_URL + '/addNotification',
    GET_NUMBER_OF_LEAD_GENERATED_BY_MEMBER: BASE_URL + '/getNumberOfLeadsGeneratedByMembers?communityId=',
    GET_DISBURSED_AND_APPROVED_AMOUNT: BASE_URL + '/getDisbursedAndApprovedAmount?communityId=',
    GET_DASHBOARD_STATISTICS_BY_COMMUNITY_ID: BASE_URL + 'getDashboardStatisticsByCommunityId?communityId=',

    //Commission
    SAVE_COMMISSION: BASE_URL + '/saveOrUpdateCommissionDetails',
    GET_COMMISSION_BY_LOANID: BASE_URL + '/getByLoanId?loanId=',
    COMMISSION_CALCULATION: BASE_URL + 'commissionCalculation?loanId=',
    UPDATE_COMMISSION_BY_LOANID: BASE_URL + '/updateCommissionDetailByLoanId?loanId=',
    GET_COMMUNITY_DASHBOARD_DATA: BASE_URL + '/getDashboardStatisticsByCommunityId?communityId=',
    GET_ALL_BANKS: BASE_URL + '/bank/getDistinctBanks',
    GET_WEBSITE_BANK_LIST: BASE_URL + '/bank/getBankDetailWebsite?pageNumber=',
    READ_COMMENTS: BASE_URL + '/makeCommentsReaded?loanId=',
    UPLOAD_OTHER_DOCUMENTS: BASE_URL + '/saveAndUploadFileAndDocDetailOther',
    GET_DOC_NAME_SEARCH: BASE_URL + '/searchByDocName?search=',
    GET_QR_DATA: BASE_URL + '/getQrDetails?qrId=',
    ADD_OR_UPDATE_QR: BASE_URL + '/saveOrUpdateQrDetails',
    GET_QR_COUNT: BASE_URL + '/getQRDetailsCount?qrId=',

    SAVE_BANK_FAQ: BASE_URL + '/saveOrUpdateBankFaq',
    // GET_BANK_FAQ: BASE_URL + '/getBankFaq?bankId=',

    SAVE_BANK_DOCS: BASE_URL + '/saveOrUpdateBankDocs',
    GET_BANK_DOCS: BASE_URL + '/getBankDocs?bankId=',

    RESET_PASSWORD_BY_COMMUNITY_ID: BASE_URL + '/resetCommunityPasswordByCommunityId?communityId=',
    ASSIGN_EMPLOYEE_LOAN: BASE_URL + "/assignEmployeeId?employeeId=",

    //CHAPTER APIS
    GET_CHAPTER_DETAILS: BASE_URL + "/getChapterDetails?bank=",
    GET_CHAPTER_DETAILS_BY_CHAPTER_ID: BASE_URL + "/getChapterDetailsByChapterId?chapterId=",
    GET_CHAPTER_DETAILS_BY_RM_ID: BASE_URL + "/getChapterByRMId?rmId=",
    GET_QR_DETAILS_BY_RM_ID: BASE_URL + "/getQRByRMId?rmId=",

    GET_RM_DETAILS_BY_QR_ID: BASE_URL + "/getRmByQrId?qrId=",

    GET_CHAPTER_DETAILS_COUNT: BASE_URL + '/getChapterDetailsCount?bank',
    SAVE_CHAPTER_DETAILS: BASE_URL + "/saveOrUpdateChapter",
    ADD_COMMUNITY_TO_CHAPTER: BASE_URL + '/addCommunityToChapterByEmailId?emailId=',
    ADD_BANK_TO_CHAPTER: BASE_URL + '/addBankBranchToChapterByIfsc?ifsc=',

    SEARCH_RM_EMAIL: BASE_URL + '/searchRms?search=',
    ADD_RM_TO_CHAPTER: BASE_URL + '/addRMToChapter?chapterId=',
    ADD_RM_TO_MARKETPLACE: BASE_URL + '/addRmToMarketPlace?qrId=',


    GET_RM_BY_CHAPTER: BASE_URL + '/getRMbyChapter?chapterId=',

    GET_BANK_BY_CHAPTER: BASE_URL + '/getBankBranchesListByChapterId?chapterId=',
    GET_COMMUNITY_BY_CHAPTER: BASE_URL + '/getCommunityListByChapterId?chapterId=',
    DELETE_CHAPTER: BASE_URL + "/deleteChapter?chapterId=",
    GET_ASSIGNED_LENDERS_BY_LOANID: BASE_URL + '/getLenderForLead?loanId=',

    // Enterprise Data
    UPLOAD_BULK_ENTERPRISE_DATA: BASE_URL + '/uploadEnterprisesThroughXls',
    GET_ENTERPRISE_DATA: BASE_URL + '/getEnterpriseCharacterList?pageNumber=',
    GET_ENTERPRISE_DATA_COUNT: BASE_URL + '/getEnterpriseCharacterListCount?cinNumber=',

    //Custom link
    GENERATE_CUSTOM_LINK: BASE_URL + '/generateCustomLink',
    GET_CUSTOM_LINK: BASE_URL + '/getCustomLinkByLinkId?linkId=',
    GET_CUSTOM_LINK_BY_LOANID: BASE_URL + '/getCustomLinkByLoanId?loanId=',
    UPDATE_RATING: BASE_URL + '/updateRating?linkId=',


    // Opportunity
    SAVE_OPPORTUNITY: BASE_URL + '/opportunity/saveOrUpdate',
    GET_OPPORTUNITY: BASE_URL + '/opportunity/get?oppId=',
    GET_OPPORTUNITY_COUNT: BASE_URL + '/opportunity/getCount?oppId=',
    DELETE_OPPORTUNITY: BASE_URL + "/opportunity/deleteById?oppId=",

    //Tasks
    SAVE_TASKS: BASE_URL + '/tasks/save',
    GET_TASKS: BASE_URL + '/tasks/get?loanId=',
    UPDATE_TASKS: BASE_URL + '/tasks/update',
    DELETE_TASKS: BASE_URL + '/tasks/delete?id=',
    GET_WEBSITE_BANK_IMAGES: BASE_URL + '/bank/getDistinctBankNameWithIdAndUrl?loanType=',
    GET_BANK_DETAILS_BY_BANK_ID: BASE_URL + '/bank/bankInfoByBankId?bankId=',

    RESET_REMAINDER: BASE_URL + '/resetReminderCount?loanId=',

    ASSIGN_LENDER_EMP: BASE_URL + '/assignLenderEmployee?loanId=',

    // GET_LENDER_EMPLOYEE: BASE_URL + '/getEmployeesOfLender?lenderId=',
    // GET_TIME_SPENT: BASE_URL + '/getTimeSpent?loanId=',
    // START_OR_STOP_TIMER: BASE_URL + '/startOrStopTimer?loanId=',
    // SAVE_STICKY_NOTES: BASE_URL + '/saveOrUpdateStickyNotes?loanId=',
    // GET_STICKY_NOTES: BASE_URL + '/getStickyNotesByLoanId?loanId=',
    // GET_BANK_PRODUCTS_BY_BANK_ID: BASE_URL + '/nbfc/product/getByNbfcId?nbfcId=',
    // GET_PRODUCT_DETAILS_DESCRIPTION: BASE_URL + '/nbfc/product/getNbfcProductDetails?loanType=',
    // GET_BANK_DOC_PRODUCT_WISE: BASE_URL + '/getBankDocs?bankId=',
    // GET_BANK_FAQ: BASE_URL + '/getBankFaq?bankId=',
    // MARK_COMMUNITY_AS_IMP: BASE_URL + '/markCommunityImportant?communityId=',

    // SAVE_CHALLENGE: BASE_URL + '/saveChallenges',
    // GET_CHALLENGE: BASE_URL + '/getChallenges?loanId=',
    // DEL_CHALLENGE: BASE_URL + '/deleteChallenges?id=',

    // SAVE_QUERY_COMMENT: BASE_URL + '/saveOrUpdateQueryCommentByQueryId',
    // GET_QUERY_COMMENT: BASE_URL + '/getQueryCommentsByQueryId?queryId=',
    GET_LENDER_EMPLOYEE: BASE_URL + '/getEmployeesOfLender?lenderId=',
    GET_TIME_SPENT: BASE_URL + '/getTimeSpent?loanId=',
    START_OR_STOP_TIMER: BASE_URL + '/startOrStopTimer?loanId=',
    SAVE_STICKY_NOTES: BASE_URL + '/saveOrUpdateStickyNotes?loanId=',
    GET_STICKY_NOTES: BASE_URL + '/getStickyNotesByLoanId?loanId=',
    GET_BANK_PRODUCTS_BY_BANK_ID: BASE_URL + '/nbfc/product/getByNbfcId?nbfcId=',
    GET_SHEET_URL: BASE_URL + '/createSheet?loanId=',
    GET_PRODUCT_DETAILS_DESCRIPTION: BASE_URL + '/nbfc/product/getNbfcProductDetails?loanType=',
    GET_BANK_DOC_PRODUCT_WISE: BASE_URL + '/getBankDocs?bankId=',
    GET_BANK_FAQ: BASE_URL + '/getBankFaq?bankId=',
    MARK_COMMUNITY_AS_IMP: BASE_URL + '/markCommunityImportant?communityId=',
    SAVE_CHALLENGE: BASE_URL + '/saveChallenges',
    GET_CHALLENGE: BASE_URL + '/getChallenges?loanId=',
    DEL_CHALLENGE: BASE_URL + '/deleteChallenges?id=',
    SAVE_QUERY_COMMENT: BASE_URL + '/saveOrUpdateQueryCommentByQueryId',
    GET_QUERY_COMMENT: BASE_URL + '/getQueryCommentsByQueryId?queryId=',
    NEW_TREE_API: BASE_URL + '/getParentCommunityMemberDetail?communityId=',
    CHANGE_PARENT_COMMUNITY: BASE_URL + '/changeParentCommunityMember?emailId=',

    //Payouts
    SAVE_PAYOUTS: BASE_URL + '/savePayout',
    GET_PAYOUT: BASE_URL + '/getPayout?bankId=',
    GET_PAYOUT_COUNT: BASE_URL + '/getPayoutCount?bankId=',
    UPDATE_PAYOUT_STATUS: BASE_URL + '/updateShowPayoutStatus?communityId=',


    SAVE_PAYOUTS_COMMUNITY: BASE_URL + '/savePayoutCommunity',
    GET_PAYOUT_BY_COMMUNITY_ID: BASE_URL + '/getPayoutByCommunityId?communityId=',
    GET_DISTINCT_PAYOUT_BY_COMMUNITY_ID: BASE_URL + '/getDistinctPayoutByCommunityId?communityId=',
    GET_DISTINCT_PAYOUT_COUNT_BY_COMMUNITY_ID: BASE_URL + '/getDistinctPayoutCountByCommunityId?communityId=',


    //BLOG
    SAVE_BLOG: CAREPAY_BASE_URL + '/saveOrUpdateBlog',
    GET_BLOG: CAREPAY_BASE_URL + '/getBlog?blogId=',
    GET_BLOG_COUNT: CAREPAY_BASE_URL + '/getBlogCount?blogId=',
    DELETE_BLOG: CAREPAY_BASE_URL + '/deleteBlog?blogId=',
    GET_WHATS_MSG_BY_LOANID: BASE_URL + '/getWhatsAppMsgs?loanId=',
    SEND_WHATSAPP_MSG: BASE_URL + '/sendSingleTextToUser',
    UPLOAD_DOCUMENT_AND_GET_URL: BASE_URL + '/saveAndUploadFileAndDocDetailOtherUrl',
    GET_AUTOMATION_DOC: BASE_URL + '/getAutomationDocListByUserId?userId=',
    MAP_AUTOMATION_DOC: BASE_URL + '/saveAutomationDoc',

    GET_CRIF_DATA_MANUALLY: BASE_URL + '/getCrifDataManually',
    GET_CRIF_MANUAL_DATA: BASE_URL + '/getManualCrifDataList?pageNo=',
    GET_CRIF_MANUAL_DATA_BY_USERID: BASE_URL + '/getManualCrifDataByUserId?userId=',
    GET_CRIF_MANUAL_COUNT: BASE_URL + '/getManualCrifDataCount',

    DELETE_ADMIN_LEAD: BASE_URL + '/deleteLead?userId=',
    UPLOAD_ZIP_FILE: BASE_URL + '/uploadZipAndSaveUrl',

    SAVE_STORY_LINE: BASE_URL + '/saveStoryLine',
    GET_STORY_LINE: BASE_URL + '/getLeadStoryLine?loanId=',
    GET_STORY_LINE_COUNT: BASE_URL + '/getLeadStoryLineCount?loanId=',
    DELETE_STORY_LINE: BASE_URL + '/deleteLeadStoryLine?loanId=',

    SAVE_RM: BASE_URL + '/saveRMDetails',
    GET_RM: BASE_URL + '/getRmDetails?rmId=',
    GET_RM_COUNT: BASE_URL + '/getRmDetailsCount?mobileNo=',
    RM_LOGIN: BASE_URL + '/rmLogin?emailId=',

    ///doctor onboarding

    SAVE_UPDATE_DOCTOR_PERSONAL: CAREPAY_BASE_URL + '/saveOrUpdateDoctorDetails',
    SAVE_UPDATE_DOCTOR_PRACTICE: CAREPAY_BASE_URL + '/saveOrUpdateDoctorProfessionalDetails',
    SAVE_UPDATE_DOCTOR_ADDRESS: CAREPAY_BASE_URL + '/saveOrUpdateAddressDetails',
    GET_BANK_BRANCH_NEW: CAREPAY_BASE_URL + '/userDetails/codeDetail?code=',  //&type=(zip/branch)
    SAVE_UPDATE_DOCTOR_BANK: CAREPAY_BASE_URL + '/saveOrUpdateBankDetails',
    GET_DOCTOR_PERSONAL: CAREPAY_BASE_URL + '/getDoctorDetailsByDoctorId?doctorId=',
    GET_DOCTOR_PROFESSIONAL: CAREPAY_BASE_URL + '/getDoctorProfDetailsByDoctorId?doctorId=',
    GET_DOCTOR_ADDRESS: CAREPAY_BASE_URL + '/getAddressDetails?doctorId=',
    GET_DOCTOR_BANK_DETAILS: CAREPAY_BASE_URL + '/getBankDetailsByDoctorId?doctorId=',
    GET_COMMUNITY: CAREPAY_BASE_URL + '/getDoctorsList?pageNumber=',
    GET_COMMUNITY_COUNT: CAREPAY_BASE_URL + '/getDoctorsListCount?emailId=',
    UPLOAD_DOCUMENT_DOCTOR: CAREPAY_BASE_URL + '/uploadpdf',
    GENERATE_QR_DOCTOR: CAREPAY_BASE_URL + '/generateQRCode?doctorId=',
    GET_DOCUMENT_DOCTORS: CAREPAY_BASE_URL + '/getDocumentsByDoctorId?doctorId=',
    MARK_DOCTOR_AS_IMP: CAREPAY_BASE_URL + '/markDoctorImportant?doctorId=',
    DELETE_DOCTOR: CAREPAY_BASE_URL + '/deleteDoctorDetails?doctorId=',
    ADD_LEAD_API: CAREPAY_BASE_URL + '/addLead',
    GET_LEADS_BY_DOCTOR_ID: CAREPAY_BASE_URL + '/getLead?doctorId=',
    GET_LEADS_COUNT_BY_DOCTOR_ID: CAREPAY_BASE_URL + '/getLeadCount?doctorId=',
    SEND_MAIL_TO_DOCTOR: CAREPAY_BASE_URL + '/sendWelcomeMailToDoctor?doctorId=',
    VERIFY_DOCTOR_API: CAREPAY_BASE_URL + '/verifyDoctor?doctorId=',
    GET_PATIENT_BASIC_DETAILS: CAREPAY_BASE_URL + '/userDetails/getUserDetailsByUserId?userId=',
    GET_PATIENT_ACCOUNT_DETAILS: CAREPAY_BASE_URL + '/userDetails/getAccountInfoByUserId?userId=',
    GET_PATIENT_ADDRESS_DETAILS: CAREPAY_BASE_URL + '/userDetails/getUserAddressByUserId?userId=',
    GET_PATIENT_EMPLOYMENT_DETAILS: CAREPAY_BASE_URL + '/userDetails/getUserEmploymentDetailsByUserId?userId=',
    GET_QR_DOCTOR: CAREPAY_BASE_URL + '/getQrCode?doctorId=',
    GET_DOCTOR_DASHBOARD_DATA: CAREPAY_BASE_URL + '/getAllLoansByDoctorId?doctorId=',
    GET_LOAN_DETAILS_BY_LOAN_ID_USER: CAREPAY_BASE_URL + '/userDetails/getLoanDetailsByLoanId?loanId=',
    GET_DOCTOR_ALL_LOAN_API: CAREPAY_BASE_URL + '/getAllLoansByDoctorId?doctorId=',
    GET_DOCTOR_GRAPH_DATA: CAREPAY_BASE_URL + '/loanCountTrend?doctorId=',
    GET_DASHBOARD_COUNT: CAREPAY_BASE_URL + '/leadCounting?nbfcId=',
    GET_LOAN_SUMMARY: CAREPAY_BASE_URL + '/loanSummary?nbfcId=',
    SEND_PROFILE_COMPLETION_LINK_TO_USER: CAREPAY_BASE_URL + '/sendProfileCompletion?doctorId=',
    SAVE_OR_UPDATE_QUERY: CAREPAY_BASE_URL + '/saveOrUpdateQuery',
    ASSIGN_APPILICATION_TO_NBFC: CAREPAY_BASE_URL + '/chooseNbfcManually?nbfcId=',
    STATUS_ACTIVITY_DATA: CAREPAY_BASE_URL + '/loan/getLoanTrack?loanId=',
    GET_SUPER_ADMIN_COUNT: CAREPAY_BASE_URL + '/getTotalCount',
    GET_STATUS_WISE_COUNT_SUPER_ADMIN: CAREPAY_BASE_URL + '/getStatusWiseCounting?startDate=',
    GET_GRAPH_DATA_SUPER_ADMIN: CAREPAY_BASE_URL + '/loanCountTrendForAdmin?startDate=',
    GET_ALL_SCOUTS_API: CAREPAY_BASE_URL + '/getScout?pageNo=',
    GET_ALL_SCOUTS_COUNT_API: CAREPAY_BASE_URL + '/getScoutCount?scoutId=',
    SAVE_SCOUT_API: CAREPAY_BASE_URL + '/saveOrUpdateScout',
    DELETE_SCOUT: CAREPAY_BASE_URL + '/deleteScout?scoutId=',
    GET_NOTIFICATIONS_CAREPAY: CAREPAY_BASE_URL + '/getNotificationDetails',
    GET_ROLE_BY_EMAIL_ID: CAREPAY_BASE_URL + '/gelRolesByEmailId?emailId=',
    GET_ALL_SCOUTS_CODE: CAREPAY_BASE_URL + '/getAllScoutCodes',
    RESET_ADMIN_PASSWORD: CAREPAY_BASE_URL + '/resetAdminPassword?email=',
    GET_QUERIES_API: CAREPAY_BASE_URL + '/getQuery',
    COMMUNITY_UPDATE_QUERY_STATUS: CAREPAY_BASE_URL + '/updateQueryStatus?id=',
    GET_CONTACT: CAREPAY_BASE_URL + '/getContactUsDetail?pageNumber=',
    DELETE_CONTACT: CAREPAY_BASE_URL + '/deleteContactUsDetail?id=',
    GET_CONTACTS_COUNT: CAREPAY_BASE_URL + '/getContactUSCount?date=',
    SEND_MAIL_TO_EMAIL_ID: CAREPAY_BASE_URL + '/sendQrCodeUrl?emailId=',
    ADD_MERCHANT_OPPORTUNITY: CAREPAY_BASE_URL + '/saveOrUpdateDoctorOpportunities',
    GET_MERCHANT_OPPORTUNITY: CAREPAY_BASE_URL + '/getDoctorOpportunitiesDetail?id=',
    GET_MERCHANT_OPPORTUNITY_COUNT: CAREPAY_BASE_URL + '/getDoctorOpportunitiesCount?id=',
    SAVE_QR_INSTALLATION: CAREPAY_BASE_URL + '/changeQrInstallationStatus?doctorId=',
    DELETE_OPPORTUNITY_MERCHANT: CAREPAY_BASE_URL + '/deleteDoctorOpportunitiesDetail?id=',
    SAVE_DOCTOR_PRODUCT: CAREPAY_BASE_URL + '/saveOrUpdateMerchantOffer',
    GET_SAVED_PRODUCTS_BY_DOCTOR_ID: CAREPAY_BASE_URL + '/getMerchantOffers?doctorId=',
    GET_SAVED_PRODUCTS_BY_DOCTOR_ID_COUNT: CAREPAY_BASE_URL + '/getMerchantOffersCount?doctorId=',
    DELETE_PRODUCT_BY_ID: CAREPAY_BASE_URL + '/deleteMerchantOffer?doctorId=',
    GET_DOCTOR_DISPUTED_DATA: CAREPAY_BASE_URL + '/getDoctorDisputeDetail?pageNo=',
    GENERATE_CREDIT_OP_REPORT: CAREPAY_BASE_URL + '/downloadLoanDataReport?startDate=',
    GENERATE_CREDIT_REPORT: CAREPAY_BASE_URL + '/generateDoctorSheet?startDate=',
    CHANGE_DISPUTE_STATUS: CAREPAY_BASE_URL + '/changeDoctorDisputeStatus?loanId=',
    CHANGE_LOAN_STATUS: CAREPAY_BASE_URL + '/changeLoanStatus?loanId=',
    SAVE_CAREPAY_NEWS_IMAGE: CAREPAY_BASE_URL + '/uploadNewsImage',
    SAVE_COMM_CONFIG_DATA: CAREPAY_BASE_URL + '/saveCommMappingConfig',
    GET_COMM_CONFIG_DATA: CAREPAY_BASE_URL + '/getCommMappingConfig?communicationCode=',
    GET_CONFIG_COUNT: CAREPAY_BASE_URL + '/getCommConfigCount?communicationCode=',
    DELETE_COMM_BY_ID: CAREPAY_BASE_URL + '/deleteCommMappingConfig?communicationId=',
    SAVE_TEXT_MSG_CONFIG: CAREPAY_BASE_URL + '/saveOrUpdateTextConfig',
    GET_MESSAGE_ALL_FIELD: CAREPAY_BASE_URL + '/getAllDataFields',
    GET_DATA_BY_COMMUNICATION_ID: CAREPAY_BASE_URL + '/getTextConfigDetail?communicationCode=',
    GET_WHATSAPP_TEXT_BY_COMMUNICATION_CODE: CAREPAY_BASE_URL + '/getWhatsAppConfigDetail?communicationCode=',
    SAVE_WHATSAPP_TEXT_MESSAGE: CAREPAY_BASE_URL + '/saveOrUpdateWhatsAppConfig',
    GET_ALL_COMMUNICATION_ADATA: CAREPAY_BASE_URL + '/getCommunicationData?type=',
    GET_ALL_STAGES: CAREPAY_BASE_URL + '/getAllStages?flow=',
    GET_DATA_ACCORDING_TO_FLOW: CAREPAY_BASE_URL + '/getCommunicationCodeDetails?flow=',
    CHANGE_STATUS_OF_TEMPLATE: CAREPAY_BASE_URL + '/changeCodeStatus?communicationCode=',
    GET_CARE_AI_DOCTOR_DATA: CAREPAY_BASE_URL + '/getDoctorAIData?doctorCode=',
    COMMUNICATE_FROM_ACTIVITY: CAREPAY_BASE_URL + '/userDetails/communicateFromActivity?communicationCodes=',
    GET_CAREPAY_COMM_LOGS: CAREPAY_BASE_URL + '/userDetails/getCommunicationDetail?userId=',
    SAVE_USER_LOAN: CAREPAY_BASE_URL + '/userDetails/saveLoanDetails',
    GET_PROFILE_COMPLITION_LINK: CAREPAY_BASE_URL + '/getProfileCompletionLink?doctorId=',
    SAVE_CONFIG_MAIL_TEMPLATE: CAREPAY_BASE_URL + '/saveOrUpdateEmailConfig',
    GET_EMAIL_BY_COMMUNICATION_CODE: CAREPAY_BASE_URL + '/getEmailConfigDetail?communicationCode=',
    CHANGE_DOCTOR_STATUS: CAREPAY_BASE_URL + '/changeDoctorStatusByDoctorId?doctorId=',
    GET_ADMIN_LOAN_DATA: CAREPAY_BASE_URL + '/getLoanAmountDetail?startDate=',
    GENERATE_CAPTCHA: CAREPAY_BASE_URL + '/api/captcha/generate',
    VERIFY_CAPTCHA: CAREPAY_BASE_URL + '/api/captcha/verify',
    GET_REFRESH_TOKEN: CAREPAY_BASE_URL + '/auth/refresh?refreshToken='






}
